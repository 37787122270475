@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,600,700');


body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

button, input, select, textarea {
  font-family: 'Roboto', sans-serif;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0,0,0,.01) 0 0 1px;
}

h1,h2,h3 {
  margin: 0;
  font-weight: 300;
}

hr {
  border: 0.5px solid #e7e7e7;
  margin: 16px 0px;
}

textarea {
  width: 320px;
  resize: none;
  max-width: 100%;
  height: 97px;
  font-size: 16px;
  outline: 0;
  border: 1px solid #e7e7e7;
  background: rgb(252, 252, 252);
  padding: 6px 8px;
  transition: .2s;
}
.icon-btn {
  padding: 10px 12px;
}
.icon-btn-compact {
  padding:7px 10px;
}
.icon-btn i {
  margin-right:8px;
}

.app-container {
  display: grid;
  grid-template-columns: 245px 1fr;
  grid-template-rows: 100%;
  height:100vh;
}
.app-container-call {
  grid-template-columns: 325px 1fr;
}
.app-container .app-view {
  background: #f4f4f4;
  position: relative;
  padding:16px;
  overflow: auto;
}
.app-container .app-user-con {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid #e7e7e7;
}
.app-container .app-settings {
  border-bottom: 1px solid #e7e7e7;
}
.app-container .app-user-con > * {
  display: grid;
  grid-template-columns: 28px auto;
  align-items: center;
  padding: 12px 16px 12px 16px;
  transition: .2s;
  cursor: pointer;
  text-decoration: none;
}
.app-container .app-user-con > *:hover {
  background: #f4f4f4;
}
.app-container .app-user-con > * i {
  font-size: 18px;
  color: #5e5e5e;
}
.app-container .app-user-con > * h2 {
  font-size: 15px;
  color: #5e5e5e;
  font-weight: 500;
}
.app-container .app-user-con > * h2 small {
  font-size: 14px;
    opacity: .6;
}
.app-container .app-navigation {
  border-right: 1px solid #e7e7e7;
  padding:8px;
  position: relative;
}
.app-container .logo {
  max-height: 66px;
    padding: 8px;
    max-width: 200px;
}

.login-wrapper .logo {
  max-height: 66px;
    padding: 8px;
    max-width: 200px;
}
.app-container .app-navigation .app-options {
  display: grid;
  position: relative;
  grid-gap: 4px;
  align-items: center;
  margin-top: 10px;
}
.app-container .app-navigation .app-options > a {
  transition: .2s;
    padding: 8px 10px;
    border-radius: 4px;
    color: #5e5e5e;
    text-decoration: none;
    background: white;
    border:1px solid transparent;
}

.app-container .app-navigation .app-options > .selected {
  color: #1f7755;
    background: rgb(238, 238, 238);
    border: 1px solid #e4e4e4;
}

.app-container .app-navigation .app-options > a:not(.selected):hover {
  background: rgb(244, 244, 244);
}

.app-container .app-navigation .app-options > a span {
  font-size: 15px;
}

.app-container .app-navigation .app-options > a i {
  font-size: 18px;
  margin-right: 8px;
}

.flip-phone {
  transform: rotate(-270deg);
}

/*grids*/
.grid-1 {display: grid;grid-template-columns: repeat(1,100%);}
.grid-2 {display: grid;grid-template-columns: repeat(2,1fr);}
.grid-3 {display: grid;grid-template-columns: repeat(3,1fr);}
.grid-4 {display: grid;grid-template-columns: repeat(4,1fr);}
.grid-3 .grid-span-2 {grid-area: span 2 / span 2}
.grid-gap {grid-gap:5px 8px}
.grid-gap-big {grid-gap:14px}
.grid-center {display:grid;align-content: center;justify-items: center}
.grid-data-migration {
  display: grid;
  grid-template-columns: 450px auto;
}

.card {
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #e7e7e7;
  overflow: hidden;
  position: relative;
}


.app-loader-con {
  background:#ffffff;
  position: fixed;
  height:100%;
  width:100%;
  z-index: 100;
  top:0;
}
.app-loader {
  width: 40px;
  height: 40px;
  position: absolute;
  top: calc(50% - 2.5px);
  left: calc(50% - 2.5px);
  transform: translate(-50%,-50%);
}
.app-loader-text {
  position: absolute;
  top: 50%;
  left: 50%;
  text-transform: uppercase;
  transform: translate(-50%,-50%);
  font-size: 16px;
  font-weight: 500;
  color: #656565;
}
.app-loader:before, .app-loader:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      border: 3px solid transparent;
      border-top-color: #4a4a4a;
  }
  .app-loader:before {
  z-index: 1;
  animation: spin 3s 0s linear infinite;
  animation-timing-function: ease-in-out;
-webkit-animation-timing-function: ease-in-out;
}

.app-loader:after {
  border: 3px solid #cacaca;
}

.modal-loader .app-loader-con {
  margin-top: 35px;
}

@keyframes spin {
  0% {
      border-top-color: #8bc34a;
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      border-top-color: #8bc34a;
      -webkit-transform: rotate(1440deg);
      -ms-transform: rotate(1440deg);
      -o-transform: rotate(1440deg);
      transform: rotate(1440deg);
  }
}


.login-wrapper {
  height: 100vh;
  background: #f4f4f4;
  display: grid;
  grid-template-columns: 400px auto;
}

.login-wrapper > div:first-child {
  display: grid;
  padding-top: 24px;
  position: relative;
  height:100%!important;
}

.login-wrapper > div:last-child {
  background-image:url(https://i.imgur.com/NlaPqDx.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height:100vh;
}

.login-container {
  padding: 16px 16px 16px 16px;
  display: grid;
  justify-content: center;
  grid-template-rows: 95px 250px;
}

label {
  display:block;
  margin-top:10px;
  margin-bottom:5px;
  font-size:14px;
  opacity: .6;
}

input:not([type="checkbox"]) {
  width:320px;
  max-width:100%;
  font-size:16px;
  outline:0;
  border:1px solid #e7e7e7;
  background:rgb(252, 252, 252);
  padding:6px 8px;
  transition:.2s;
  color: rgb(50,50,50);
}

select {
  height: 32px;
  width:320px;
  max-width:100%;
  padding-right:20px;
  font-size: 15px;
  padding: 5px 7.5px;
  outline: 0;
  border-radius: 2px;
  border: 1px solid lightgrey;
  background-clip: padding-box;
  transition: 0.2s;
  -webkit-appearance: none;
  background:white;
  cursor: pointer;
    background-size:9px;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: 98%;
    background-image:url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgMjg0LjkyOSAyODQuOTI5IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyODQuOTI5IDI4NC45Mjk7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8cGF0aCBkPSJNMjgyLjA4Miw3Ni41MTFsLTE0LjI3NC0xNC4yNzNjLTEuOTAyLTEuOTA2LTQuMDkzLTIuODU2LTYuNTctMi44NTZjLTIuNDcxLDAtNC42NjEsMC45NS02LjU2MywyLjg1NkwxNDIuNDY2LDE3NC40NDEgICBMMzAuMjYyLDYyLjI0MWMtMS45MDMtMS45MDYtNC4wOTMtMi44NTYtNi41NjctMi44NTZjLTIuNDc1LDAtNC42NjUsMC45NS02LjU2NywyLjg1NkwyLjg1Niw3Ni41MTVDMC45NSw3OC40MTcsMCw4MC42MDcsMCw4My4wODIgICBjMCwyLjQ3MywwLjk1Myw0LjY2MywyLjg1Niw2LjU2NWwxMzMuMDQzLDEzMy4wNDZjMS45MDIsMS45MDMsNC4wOTMsMi44NTQsNi41NjcsMi44NTRzNC42NjEtMC45NTEsNi41NjItMi44NTRMMjgyLjA4Miw4OS42NDcgICBjMS45MDItMS45MDMsMi44NDctNC4wOTMsMi44NDctNi41NjVDMjg0LjkyOSw4MC42MDcsMjgzLjk4NCw3OC40MTcsMjgyLjA4Miw3Ni41MTF6IiBmaWxsPSIjNDA0MDQwIi8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
}
input:hover,textarea:hover,select:hover {
  border:1px solid #60BFC7;
}

input:focus,textarea:focus,select:focus {
  border:1px solid #2F7CAE;
}

button:disabled {
  cursor: not-allowed;
}

button {
  box-shadow:inset 0 0 0 99999px rgba(255,255,255,0.1);
  border:1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  display:block;
  font-size: 16px;
  padding:8px 20px;
  color:white;
  transition:.2s;
  background: #8bc34a;
  outline:0;
  cursor:pointer;
  position: relative;
}

button .counter {
  background: #ed992b;
  border-radius: 50%;
  padding: 3px 8px;
  margin-left: 8px;
  font-weight: bold;
  font-size: 15px;
}

button:hover {
  box-shadow:none;
  border:1px solid rgba(0, 0, 0, 0.4);
}

button:focus {
  border:1px solid rgba(0, 0, 0, 0.5);
}

.night-mode a {
  color: #00ffcd;
}

.login-wrapper button {
  margin-top:20px;
}

.login-invalid {
  margin-top: 16px;
  font-size: 14px;
  color: #f99707;
}

.login-invalid i {
  margin-right: 8px;
}

.dashboard-heading {
  font-size: 16px;
    font-weight: 400;
    text-align: center;
    padding: 12px 0px 12px 0px;
    opacity: .7;
}

.recharts-legend-item-text {
  font-size: 12px;
  opacity: .7;
}

.stat h2 {
  font-size: 62px;
  font-weight: 500;
}

.stat span {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.stat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  color: white;
}
.stat-clickable {
  transition: .2s;
  cursor: pointer;
  border:8px solid transparent;
}
.stat-clickable:hover {
  border:8px solid rgba(0, 0, 0, 0.2);
}


/* table kit */
.table-kit {
  font-family: 'roboto';
  background: #f8f8f8;
  padding: 1px;
  border: 0.5px solid lightgrey;
}
.table-kit a {
  text-decoration: none;
  color: #2f7bae;
}
.table-kit .table-kit-footer input {
  width:140px;
  font-size: 14px;
  height:24px;
}
.table-kit-body {
  overflow: auto;
  position: relative;
}

.table-kit table {
  font-size: 16px;
  width: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  border-collapse: collapse;
  background: white;
  border-top: 0;
  cursor: cell;
}

.table-kit table thead tr th {
  font-size: 14px;
  padding: 7.5px;
  text-align: left;
  background: #ffffff;
  font-weight: 500;
  white-space: nowrap;
  position: sticky;
  top: 0;
  z-index: 10;
}

.table-kit table thead tr th:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom: 1px solid lightgrey;
}

.table-kit table tbody tr {
  background: white;
}

.table-kit table tbody td {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  padding: 5px 7px;
  border-bottom: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  white-space: nowrap;
  position: relative;
  max-width: 600px;
  overflow: auto;
}

.table-kit table tbody td:last-child {
  border-right: 0;
}

.table-kit table tbody tr:nth-child(even) {
  background: #fafafa;
}

.table-kit .table-kit-footer {
  background: white;
  border-top: 0.5px solid lightgrey;
  padding: 8px;
  height: 32px;
  font-size: 14px;
  display: flex;
  align-items: center;
  position: relative;
}

.table-kit-footer i {
  font-size: 14px;
  margin-right: 12px;
  color: #5a5a5a;
  cursor: pointer;
  transition: .2s;
}

.table-kit-footer i:last-of-type {
  margin-right: 8px;
}

.table-kit-footer i:hover {
  opacity: .6;
}

.table-kit table tbody tr:hover {
  background: #eeeeee;
}

#table .table-kit-body {
  height: calc(100vh - 32px)!important;
}

#table .table-kit {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh!important;
  z-index:9999;
}


.table-footer-right {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.table-pagination {
  display: flex;
  align-items: center;
}
.table-footer-right i {
  margin:0;
  font-size: 18px;
  padding: 0 8px;
}

.callable-customer-actions i {
  margin-right:4px;
  font-size: 18px;
  padding:3px;
  border-radius: 50%;
  cursor: pointer;
  transition: .2s;
}
.callable-customer-actions .fa-phone {
  color:rgb(15, 133, 15);
}
.callable-customer-actions .fa-calendar {
  color:#484848;
}
.callable-customer-actions .fa-folder {
  color: #b89705;
}
.callable-customer-actions .fa-file-signature {
  color: #1174aa;
}
.callable-customer-actions .fa-file {
  color:rgb(82, 82, 82);
} 
.callable-customer-actions .fa-times-circle {
  color:rgb(167, 30, 30);
}

.button-options {
  display: flex;
  margin-bottom: 16px;
}

.button-options button {
  margin-top:0;
  margin-right: 5px;
}

.app-crumb {
  font-size: 16px;
  margin-bottom: 14px;
}

.app-crumb a {
  text-decoration: none;
  color:rgb(119, 119, 119);
  transition: .2s;
  padding:0 5px;
  text-transform: capitalize;
}

.app-crumb a:last-of-type {
  color: rgb(58, 58, 58);
  cursor: default;
}

.app-crumb a:not(:last-of-type):hover {
  color:rgb(43, 43, 43);
}

.app-crumb a:not(:last-of-type)::after {
  content: '/';
  padding-left:5px;
  color:rgb(169, 169, 169);
}

.flex-row {
  display: flex;
  white-space: nowrap;
  overflow: auto;
}

.flex-row-padder > * {
  margin-right: 5px;
}

.inactive-btn {
  background: grey;
  border: 1px solid #606060;
}

.app-container .app-navigation .app-options .sub-option {
  margin-left: 14px;
  color: #757575;
}

.app-container .app-navigation .app-options .sub-option i, .app-container .app-navigation .app-options .sub-option span {
  font-size: 15px;
}

.app-container .app-navigation .app-options .sub-option {
  padding: 5.5px 10px;
}

.app-container .app-navigation .app-options .sub-option.selected {
background: #f6f6f6;
    border: 1px solid #ececec;
}

.week-planner {
  height:auto;
  border:1px solid lightgrey;
  background:white;
  display:flex;
  overflow: auto;
  outline:0;
}

.week-planner-plot, .week-planner-times {
  flex:1;
}
.week-planner-plot {
  display: flex;
  flex-direction: column;
}
.week-planner-times {
  max-width:100px;
  display:flex;
  flex-direction: column;
  border-right:0.5px solid lightgrey;
}
.week-planner-controls {
  height: 37px;
  font-size: 22px;
  border-bottom: 0.5px solid lightgrey;
  text-align: center;
  color: grey;
  display: flex;
  margin-bottom:20px;
}
.week-planner-controls i {
  cursor: pointer;
  padding: 6.5px;
  flex: 1;
  transition: 0.2s;
}
.week-planner-controls i:hover {
  background:#e7e7e7;
}
.week-planner-time {
  height:120px;
  padding: 0px 10px;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  color:rgb(82, 82, 82);
}
.week-planner-days {
  display: flex;
  border-bottom:0.5px solid lightgrey;
}
.week-planner-day {
  flex:1;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  padding:10px;
  color:rgb(82, 82, 82);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.week-planner-day:not(:last-child){
  border-right:0.5px solid lightgrey;
}
.week-planner-plot-area {
  cursor: cell;
  flex:1;
  background:rgb(245, 245, 245);
  display:flex;
}
.week-planner-plot-area-day {
  flex:1;
  position: relative;
  padding-top:20px;
}
.week-planner-plot-area-day:not(:last-child){
  border-right:0.5px solid lightgrey;
}
.week-planner-plot-area-node {
    position: absolute;
    display: block;
    width: 95%;
    background: #ededed;
    border: 0.5px solid lightgrey;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2px;
    padding: 4px 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.2s;
}
.week-planner-plot-area-node:hover {
  background: #e6e6e6;
}

.with-st {
  font-size: 26px;
  font-weight: 500;
  display: grid;
  opacity: .7;
}

.with-st small { 
  font-size: 16px;
  margin-top:5px;
  opacity: .7;
}

.center-contents {
  display: grid;
  height:100%;
  width:100%;
  justify-items: center;
  align-content: center;
}

.grid-center .with-st {
  justify-items: center;
}

.center-contents button {
  width:200px;
}

.file-uploader {
  height: 180px;
  width:400px;
  background: #f9f9f9;
  border: 1px solid lightgrey;
  border-radius: 4px;
  text-align: center;
  padding-top: 75px;
  cursor:pointer;
  margin:25px 0px;
  transition: .2s;
}

.file-uploader::after {
  content: 'Drop to upload';
  font-weight: 400;
  color: lightgrey;
  font-size: 20px;
}

.file-uploader:hover,.file-uploader-over {
  background: #ececec;
  border: 1px solid rgb(196, 196, 196);
}

#fileUploader{
  display: none;
}

#notification {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width:calc(100% - 245px);
  left:245px;
  bottom:0;
  z-index:10000;
}

#notification span {
  z-index: 1001;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05), 0 3px 12px rgba(0, 0, 0, 0.05);
  background:rgb(58, 58, 58);
  padding:15px;
  border-radius: 4px 4px 0 0;
  color:white;
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
  -webkit-animation-duration: .2s;
  animation-duration: .2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

#notification .close-notification {
  -webkit-animation-name: slideOut;
  animation-name: slideOut;
}

@-webkit-keyframes slideIn {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideIn {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes slideOut {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes slideOut {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

.upload-btn {
  position: absolute;
  right:14px;
}

.full-width-form input,.full-width-form select,.full-width-form textarea {
  width:100%;
}
.profile-btn {
  width:120px;
  padding:5.5px;
}

.call-nav-overlay {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  min-height: 320px;
  overflow: auto;
  background: white;
  z-index: 1;
  padding: 0 8px;
  height: calc(100vh - 99px);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.call-nav-overlay .timer {
  font-size: 42px;
    font-weight: 600;
    color: #404040;
}

@media only screen and (max-width: 1200px){
  .grid-3 .grid-span-2 {
    grid-area: span 3 / span 3;
    max-height:unset!important;
  }
  .grid-3 .grid-span-2 + * {
    grid-area: span 3 / span 3;
  }
}

.user-profile-grid {
  grid-template-columns: minmax(max-content, 420px) minmax(max-content, 350px);
  display: grid;
}

.pricing-actions .fa-lock {
  color:#c6a114;
  cursor: pointer;
}

.pricing-actions .fa-lock::after {
  content: 'Closed';
  font-size:14px;
  font-family: sans-serif;
  margin-left:4px;
}

.pricing-actions .fa-lock-open {
  color:#636363;
  cursor: pointer;
}

.pricing-actions .fa-lock-open::after {
  content: 'Open';
  font-size:14px;
  font-family: sans-serif;
  margin-left:4px;
}

.admin-actions .fa-file-signature,.pricing-actions .fa-file-signature {
  color:#1396c6;
  cursor: pointer;
}

.admin-actions .fa-file-signature::after,.pricing-actions .fa-file-signature::after {
  content: 'Level One';
  font-size:14px;
  font-family: sans-serif;
  margin-left:4px;
}

.admin-actions .fa-file-contract,.pricing-actions .fa-file-contract {
  color:#0e9656;
  cursor: pointer;
}

.admin-actions .fa-file-contract::after,.pricing-actions .fa-file-contract::after {
  content: 'Level Two';
  font-size:14px;
  font-family: sans-serif;
  margin-left:4px;
}

.admin-actions .fa-file-certificate {
  cursor: pointer;
  color: #ca8a1d;
}

.admin-actions > *:not(:first-child),.pricing-actions > *:not(:first-child){
  margin-left:10px;
}


.script {
  font-size: 13px;
}

.app-helper {
  position: fixed;
  bottom: 14px;
  right: 14px;
  -webkit-animation-duration: .2s;
  animation-duration: .2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeUp;
  animation-name: fadeUp;
  z-index: 10000;
}

.app-helper .bar {
  height: 40px;
  width: 40px;
  padding: 6px;
  border-radius: 6px;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  right: 0;
  cursor: pointer;
  -webkit-box-shadow: 1px 3px 16px rgba(0,0,0,.15);
  box-shadow: 1px 3px 16px rgba(0,0,0,.15);
  z-index: 2;
  display: -ms-flexbox;
  display: flex;
  background: #007db3;
  position: relative;
  -webkit-transition-delay: .2s;
  -o-transition-delay: .2s;
  transition-delay: .2s;
}

.app-helper .bar i {
  -ms-flex-item-align: end;
  align-self: flex-end;
  width: inherit;
  font-size: 24px;
  color:white;
  text-align: right;
  padding-right:9px;
  padding-bottom: 3px;
  transition: .2s;
}

.app-helper .con {
  height: 475px;
  max-height: calc(100vh - 80px);
  background: #fff;
  -webkit-box-shadow: 1px 3px 16px rgba(0,0,0,.15);
  box-shadow: 1px 3px 16px rgba(0,0,0,.15);
  position: fixed;
  bottom: 14px;
  border-radius: 4px 0 0 4px;
  right: 46px;
  z-index: 1;
  width: 0;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
  overflow: hidden;
}

.app-helper .con.expand {
  width: 350px;
  -webkit-transition-delay: .2s;
  -o-transition-delay: .2s;
  transition-delay: .2s;
}

.app-helper .bar.expand {
  height: 475px;
  width: 32px;
  padding: 5px;
  border-radius: 0 6px 6px 0;
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.app-helper .bar.expand i {
  font-size: 18px;
  padding-right:8px;
}

.app-helper .con.expand>div {
  padding: 16px;
  width: 350px;
  opacity: 1;
}

.app-helper ul {
  margin: 0;
    padding: 10px 0px;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap:10px;
    text-align: center;
}

.app-helper li.selected {
  background: rgb(238, 238, 238);
  border: 1px solid #e4e4e4;
}

.app-helper li {
  padding: 8px;
  font-size: 14px;
  color: #5e5e5e;
  transition: .2s;
  cursor: pointer;
  border: 1px solid transparent;
}

.app-helper li i {
  margin-right:8px;
}

.app-helper li:hover {
  background: rgb(244, 244, 244);
}

.app-helper .con>div {
  -webkit-transition: .2;
  -o-transition: .2;
  transition: .2;
  opacity: 0;
}

.app-helper .con h4 {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  font-size: 18px;
}

.app-helper .con small {
  display: block;
  opacity: .7;
  font-size: 14px;
  margin-top: 2px;
}

.app-helper p {
  margin: 0;
}

.app-helper .help-result {
  background: #f9f9f9;
    border: .5px solid #d3d3d3;
    margin: 8px 0;
    border-radius: 2px;
    padding: 7px 10px 7px 12px;
    cursor: pointer;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}

.app-helper .help-result p {
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}

.app-helper .help-result p:first-child {
  font-weight: 500;
}

.app-helper .help-result p:nth-child(2) {
  color: #4e4e4e;
  margin: 2px 0;
}

.app-helper .help-results {
  height: 352px;
  overflow: auto;
}

.app-helper .help-result:hover {
  background: #ececec;
  border: .5px solid #b9b9b9;
}

.app-helper .article-text {
  line-height: 1.4;
  color: #525252;
  overflow: auto;
  height: 380px;
}

.app-helper .helper-back {
  font-size: 22px;
  color: #4f4f4f;
  cursor: pointer;
}

.app-navigation .mobile-logo {
  display: none;
  width:34px;
  padding: 4px 5px;
}

@media only screen and (max-width: 1200px){
  .grid-data-migration {
    grid-template-columns: repeat(1,auto);
  }
  .app-options a span {
    display: none;
  }
  .app-container .app-user-con > * h2 {
    display: none;
  }
  .app-container {
    grid-template-columns: 50px 1fr;
  }
  .app-container-call {
    grid-template-columns: 250px 1fr;
  }
  .app-container-call .app-navigation .app-options {
    margin-top:0;
  }
  .app-navigation .logo {
    display: none;
  }
  .app-navigation .mobile-logo {
    display: block;
  }
  .app-container .app-navigation .app-options > a {
    padding: 4px 5px;
    text-align: center;
    width:34px;
  }
  .app-container .app-navigation .app-options > a i {
    margin:0;
  }
  .app-container .app-navigation .app-options .sub-option {
    margin-left: 0;
  }
  .app-container .app-navigation .app-options .sub-option {
    padding: 4px 7px;
    margin: 2px;
    width: 30px;
  }
  .app-container .app-navigation .app-options > .selected {
    background: #1f7755;
    color: rgb(238, 238, 238);
    border: 1px solid #1f7755;
  }
  .app-container .app-navigation .app-options .sub-option.selected {
    background: #2b9d71;
    border: 1px solid #2b9d71;
  }
  .app-container .app-navigation .app-options > a:not(.selected):hover {
    background: rgb(223, 223, 223);
  }
  .stat {
    height: 120px!important;
  }
  .stat h2 {
    font-size: 36px;
  }
  .flex-row {
    white-space: nowrap;
    overflow: auto;
  }
  .call-nav-overlay {
    height: calc(100vh - 100px);
  }
  .app-container .app-user-con > div:nth-child(2) {
    display: none;
  }
}

.editor-wrapper {
  border: 1px solid #f1f1f1;
}

.editor-wrapper .rdw-editor-main {
  height: 200px;
  padding: 0 14px;
}

.table-kit-error {
  display: grid;
    justify-content: center;
    align-content: center;
    text-align: center;
    opacity: .7;
    height:100%;
}

.table-kit-error i {
  font-size: 36px;
}

.table-kit-error p {
  font-size: 18px;
}
.sales-home-view-selector,.dashboard-view-selector {
  position: absolute;
    top: 9px;
    right: 16px;
    z-index:1;
}


.search-input {
  position: relative;
}
.search-input-results {
  position: absolute;
  background: white;
  width: 100%;
  z-index: 100;
  -webkit-box-shadow: 0 7px 14px 0 rgba(50,50,93,.1), 0 3px 6px 0 rgba(0,0,0,.07);
  box-shadow: 0 7px 14px 0 rgba(210, 210, 210, 0.2), 0 3px 6px 0 rgba(0, 0, 0, 0.14);
  border-radius: 0 0 3px 3px;
  max-height: 190px;
  overflow: auto;
  height: 0;
}
.search-input-node:not(:last-child) {
  border-bottom:0.5px solid rgb(231, 231, 231);
}

.search-input-node {
  padding:9px 10px;
  transition: 0.2s;
  cursor: pointer;
}

.search-input-node:hover {
  background: #f5f5f5;
}

.search-input-node p {
  margin: 0;
}

.search-input-node p:nth-child(1){
  font-weight: 500;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: black;
}

.search-input-node p:nth-child(2){
  color: #4e4e4e;
  margin: 2px 0 0 0;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.search-input-node p:nth-child(3){
  font-size: 12px;
  color: grey;
  margin-top:2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}



.ql-editor {
  height:150px;
}
.ql-container.ql-snow {
  border: 1px solid #e7e7e7;
}
.ql-toolbar.ql-snow {
  border: 1px solid #e7e7e7;
}

.dashboard-insight-stats {
  grid-template-rows: repeat(auto-fill, 140px);
}

.dashboard-container > h2 {
  font-size: 22px;
  opacity: .7;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 20px;
}
.dashboard-container h2:not(:first-child){
  margin-top:28px;
}
.dashboard-container h2 small {
  display: block;
  margin-top: 5px;
  opacity: .6;
}

.dashboard-stat {
  width: 100%;
  padding: 32px;
  display: grid;
  align-items: center;
}
.dashboard-stat h3 {
  font-weight: 500;
}
.dashboard-stat p {
  font-size: 58px;
  font-weight: 500;
  color: #75b447;
  margin: 0;
  display: flex;
  align-items: center;
}
.dashboard-stat > span {
  margin-top: 0;
  opacity: .5;
}
.dashboard-stat i {
  margin-right: 12px;
  font-size: 38px;
  opacity: .4;
}

@media only screen and (max-width:1600px){
  .dashboard-container .dashboard-stat p {
    font-size: 46px;
  }
  .dashboard-container .dashboard-stat h3 {
    font-size: 16px;
  }
  .dashboard-container .dashboard-stat > span {
    font-size: 13px;
  }
  .dashboard-container .dashboard-stat i {
    font-size: 28px;
  }
  .dashboard-container .dashboard-stat {
    height:180px!important;
  }
}

@media only screen and (max-width:1000px){
  .dashboard-container .grid-4 {
    grid-template-columns: repeat(2,1fr);
  }
}


.small-sidebar .app-options a span {
  display: none;
}
.small-sidebar .app-container {
  grid-template-columns: 50px 1fr;
}
.small-sidebar .app-container-call {
  grid-template-columns: 250px 1fr;
}
.small-sidebar .app-container-call .app-navigation .app-options {
  margin-top:0;
}
.small-sidebar .app-navigation .logo {
  display: none;
}
.small-sidebar .app-navigation .mobile-logo {
  display: block;
}
.small-sidebar .app-container .app-navigation .app-options > a {
  padding: 4px 5px;
  text-align: center;
  width:34px;
}
.small-sidebar .app-container .app-navigation .app-options > a i {
  margin:0;
}
.small-sidebar .app-container .app-navigation .app-options .sub-option {
  margin-left: 0;
}
.small-sidebar .app-container .app-navigation .app-options .sub-option {
  padding: 4px 7px;
  margin: 2px;
  width: 30px;
}
.small-sidebar .app-container .app-navigation .app-options > .selected {
  background: #006f8a;
  color: rgb(238, 238, 238);
  border: 1px solid #006f8a;
}
.small-sidebar .app-container .app-navigation .app-options .sub-option.selected {
  background: #60BFC7;
  border: 1px solid #60BFC7;
}
.small-sidebar .app-container .app-navigation .app-options > a:not(.selected):hover {
  background: rgb(223, 223, 223);
}
.small-sidebar .app-container .app-user-con > * h2 {
  display: none;
}

@media only screen and (max-width: 700px){
  .dashboard-container .grid-4 {
    grid-template-columns: repeat(1,1fr);
  }
  .login-wrapper > div { 
    width:100%!important;
  }
  .login-wrapper {
    align-items: unset;
      padding: 8px;
  }
  .app-container {
    grid-template-columns: 43px 1fr;
  }
  .app-container .app-navigation {
    border-right: 1px solid #e7e7e7;
    padding: 4px;
  }
  .app-container .app-user-con > * {
    padding: 12px 16px 12px 13px;
  }
  .sales-home-view-selector, .dashboard-view-selector {
    position: unset;
    margin-bottom:10px;
  }
  .dashboard-container > h2 {
    font-size: 16px;
    margin-top: 14px;
  }
  .dashboard-container .dashboard-stat {
    height: 100px!important;
}
.dashboard-stat {
  padding: 14px 16px;
}
.dashboard-container .dashboard-stat p {
  font-size: 28px;
}
.dashboard-container .dashboard-stat i {
  font-size: 20px;
}
.dashboard-chart-con .card {
  height:250px!important;
}
.dashboard-chart-con {
  grid-template-columns: repeat(1,1fr);
}
.with-st {
  font-size: 18px;
}
.with-st small {
  font-size: 14px;
}
.stat {
  height: 85px!important;
}
.stat span {
  font-size: 15px;
}
.stat h2 {
  font-size: 28px;
}
.table-kit table {
  font-size: 12px;
}
.app-helper {
  display: none;
}
.grid-2,.user-profile-grid,.grid-3,.dashboard-chart-con {
  grid-template-columns: repeat(1,1fr)!important;
}
.card {
  overflow: auto;
}
.app-container .app-view {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
}
#search .search-con {
  position: fixed;
  display: grid;
  width: 100%;
  height: 100vh;
  padding: 16px;
  background: rgba(0,0,0,0.5);
  z-index: 10000;
  top: 0;
  left: 0;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  grid-template-columns: minmax(auto,510px);
  -webkit-animation-duration: .2s;
  animation-duration: .2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.search-con .search-input i {
  position: absolute;
    margin-top: 14px;
    margin-left: 12px;
    font-size: 22px;
   color: #2F7CAE;
}

.search-con input {
  width: 100%;
    font-size: 18px;
    padding: 14px 12px 14px 48px;
    border:1px solid transparent!important;
    border-radius: 6px;
}
.search-con input:hover {
  background:#eeeeee;
  transition: .2s;
}
.search-con .search-results {
  transition: .2s;
  height: 53px;
  margin-bottom: 20vh;
}
.search-con .search-results-open {
  padding:14px 14px 0px 14px;
  height:600px;
  margin-bottom: 0;
}
.search-con .search-results-open input {
  border:1px solid #e7e7e7!important;
}
.search-con .search-results-con {
  height:532px;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
/* .search-open {
  filter: blur(3px);
} */

/* night mode */
.night-mode .app-crumb a:not(:last-of-type):hover {
  color:rgb(163, 163, 163);
}
.night-mode .app-navigation {
  background: #2c2c32;
  border-right: 1px solid #404050;
}
.night-mode .app-user-con {
  color: white;
  border-top: 1px solid #404050;
}
.night-mode .app-container .app-settings {
  border-bottom: 1px solid #404050;
}
.night-mode .card {
  background: #2c2c32;
  border: 1px solid #404050;
  color: white;
}
.night-mode input, .night-mode select, .night-mode textarea {
  border: 1px solid #404050;
    background: #26262b;
    color:white;
}
.night-mode .app-user-con > *:hover {
  background: #272727;
}
.night-mode .app-user-con > * h2, .night-mode .app-user-con > * i {
  color:white;
}
.night-mode .app-crumb a {
  color: rgb(197, 197, 197);
}
.night-mode .app-crumb a:last-of-type {
  color: rgb(255, 255, 255);
}
.night-mode .app-navigation .app-options > a {
  color: white;
  background: none;
}
.night-mode .app-navigation .app-options > a:not(.selected):hover {
  background: #222227!important;
}
.night-mode .app-navigation .app-options > .selected {
  color: #ffffff;
  background: #1c1c20;
  border: 1px solid #272727;
}
.night-mode .app-navigation .app-options .sub-option.selected {
  border: 1px solid #272727;
  background: #1c1c20;
}
.night-mode .app-navigation .app-options .sub-option {
  color: #b0b0b0;
}
.night-mode .app-view {
  background: #212125;
}
.night-mode hr {
  border: 0.5px solid #404050;
}
.night-mode .app-helper .con {
  background:#2c2c32;
}
.night-mode .app-helper li.selected {
  background: rgb(20, 20, 20);
  border: 1px solid #212125;
}
.night-mode .app-helper li {
  color: #fff;
}
.night-mode .app-helper .con h4 {
  color:#fff;
}
.night-mode .app-helper li:hover {
  background: #212125;
}
.night-mode .app-helper .help-result {
  background: #424242;
  border: .5px solid #404050;
  color: white;
}
.night-mode .app-helper .help-result p:nth-child(2) {
  color: #929292;
}
.night-mode .app-helper .help-result:hover {
  background: #212125;
}
.night-mode .app-helper .article-text {
  color:white;
}
.night-mode .app-helper .helper-back {
  color: #bbbbbb;
}
.night-mode .app-loader-con {
  background:#212125!important;
}
.night-mode .app-loader:after {
  border: 3px solid #505050;
}
.night-mode .table-kit {
  background: #2c2c32;
  border: 0.5px solid #404050;
}
.night-mode .table-kit .table-kit-footer {
  background: #2c2c32;
  border-top: 0.5px solid #404050;
}
.night-mode .table-kit-footer i {
  color:white;
}
.night-mode .dashboard-container > h2 {
  color:white;
}
.night-mode .table-kit table {
  background: #2c2c32;
}
.night-mode .table-kit table thead tr th {
  background: #2c2c32;
}
.night-mode .table-kit table tbody tr {
  background: #37373e!important;
}
.night-mode .table-kit table tbody tr:nth-child(even) {
  background: #37373e!important;
}
.night-mode .table-kit table tbody td {
  border-bottom: 1px solid #404050;
    border-right: 1px solid #404050;
}
.night-mode .table-kit table thead tr th:after {
  border-bottom: 1px solid #404050;
}
.night-mode .table-kit table tbody tr:hover {
  background: #191919!important;
}
.night-mode .call-nav-overlay {
  background: #2c2c32;
  color: white;
}
.night-mode .call-nav-overlay .timer {
  background: #2c2c32;
  color: white;
}
.night-mode .week-planner {
  border: 1px solid #404050;
  background: #2c2c32;
}
.night-mode .week-planner-times {
  border-right: 0.5px solid #404050;
}
.night-mode .week-planner-days {
  border-bottom: 0.5px solid #404050;
}
.night-mode .week-planner-day:not(:last-child) {
  border-right: 0.5px solid #404050;
}
.night-mode .week-planner-day, .night-mode .week-planner-time {
  color: rgb(255, 255, 255);
}
.night-mode .week-planner-plot-area {
  background: rgb(68, 68, 68);
}
.night-mode .week-planner-plot-area-day:not(:last-child) {
  border-right: 0.5px solid #404050;
}
.night-mode .week-planner-controls {
  border-bottom: 0.5px solid #404050;
  color: white;
}
.night-mode .week-planner-controls i:hover {
  background: #464646;
}
.night-mode .agent-stats .card {
  color:white!important;
}
.night-mode .inactive-btn {
  background: #2c2c2c;
  border: 1px solid #404050;
}
.night-mode select {
  background-size: 9px;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 98%;
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgMjg0LjkyOSAyODQuOTI5IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyODQuOTI5IDI4NC45Mjk7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8cGF0aCBkPSJNMjgyLjA4Miw3Ni41MTFsLTE0LjI3NC0xNC4yNzNjLTEuOTAyLTEuOTA2LTQuMDkzLTIuODU2LTYuNTctMi44NTZjLTIuNDcxLDAtNC42NjEsMC45NS02LjU2MywyLjg1NkwxNDIuNDY2LDE3NC40NDEgICBMMzAuMjYyLDYyLjI0MWMtMS45MDMtMS45MDYtNC4wOTMtMi44NTYtNi41NjctMi44NTZjLTIuNDc1LDAtNC42NjUsMC45NS02LjU2NywyLjg1NkwyLjg1Niw3Ni41MTVDMC45NSw3OC40MTcsMCw4MC42MDcsMCw4My4wODIgICBjMCwyLjQ3MywwLjk1Myw0LjY2MywyLjg1Niw2LjU2NWwxMzMuMDQzLDEzMy4wNDZjMS45MDIsMS45MDMsNC4wOTMsMi44NTQsNi41NjcsMi44NTRzNC42NjEtMC45NTEsNi41NjItMi44NTRMMjgyLjA4Miw4OS42NDcgICBjMS45MDItMS45MDMsMi44NDctNC4wOTMsMi44NDctNi41NjVDMjg0LjkyOSw4MC42MDcsMjgzLjk4NCw3OC40MTcsMjgyLjA4Miw3Ni41MTF6IiBmaWxsPSIjZmZmZmZmIi8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
}
.night-mode .ql-editor {
  background: #26262b;
    color: white;
}
.night-mode .ql-container.ql-snow {
  border: 1px solid #404050;
}
.night-mode .ql-toolbar.ql-snow {
  border: 1px solid #404050;
}
.night-mode .ql-snow .ql-picker {
  color: #fff;
}
.night-mode .ql-snow .ql-stroke {
  stroke: #fff;
}
.night-mode .ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button {
  padding: 3px 2px;
  margin-right: 3px;
  width: 22px;
}
.night-mode .ql-snow .ql-picker-options {
  background-color: #424242;
}
.dashboard-chart-con {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
}

.search-results-con {
  overflow: auto;
}

.search-results-con a {
  border: 1px solid #e7e6e6;
    display: grid;
    margin: 8px 0px;
    padding: 12px;
    text-decoration: none;
    color: black;
    background: #f4f4f4;
    grid-gap: 4px;
    transition:0.2s;
    border-radius: 4px;
}
.search-results-con p {
  margin: 0;
  opacity: .8;
  font-weight: 500;
}
.search-results-con ul {
  margin: 0;
    padding: 0;
    opacity: .6;
    font-size: 14px;
}

.search-results-con a:hover {
  border: 1px solid rgb(194, 194, 194);
  background: #e8e8e8;
}

.search-results-con ul li:not(:last-of-type)::after {
  content: '/';
  padding-left:5px;
  padding-right:5px;
}

.search-results-con ul {
  list-style:none;
  display:flex;
}
.night-mode .ql-container.ql-snow {
  border: 1px solid #4a4a4a;
}
.night-mode .ql-toolbar.ql-snow {
  border: 1px solid #4a4a4a;
}
.night-mode .ql-snow .ql-picker {
  color: #fff;
}
.night-mode .ql-snow .ql-stroke {
  stroke: #fff;
}
.night-mode .ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button {
  padding: 3px 2px;
  margin-right: 3px;
  width: 22px;
}
.night-mode .ql-snow .ql-picker-options {
  background-color: #424242;
}
.dashboard-chart-con {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
}